import React from "react";
import Layout from "../components/layout";
import { bigHeader } from "../components/Layout/contentHeader";
import Heading from "../components/Layout/heading";
import MaxWidth from "../components/Layout/maxWidth";
import SEO from "../components/seo";
import torch from "../images/northern_lights_mission.png";



export default function Mission() {
  return (
    <Layout>
      <SEO title="Mission" />
      <Heading>
        <h1>Mission</h1>
      </Heading>
      <MaxWidth>
        <h2 className={bigHeader}>
          Meaningful research & technology development
        </h2>

        <p>
          Inprosperis e.U. is an R&D company providing its services for high
          tech companies, start-ups and other 21. century businesses. Since our
          approach is unique, we are approaching every client individually.
        </p>

        <p>
          We provide our clients futuristic, cutting edge, research and
          development solutions comprising a wide variety of the client’s needs.
          We make it our duty to ensure the success of our clients—after all—if
          they do well, a little of the light shines on us.
        </p>

        <img src={torch} style={{ marginTop: "2em" }} />
      </MaxWidth>
    </Layout>
  )
}
